import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import {
  Breadcrumb,
  Button,
  Card,
  Loader,
  Row,
  Column,
  Icon,
  Avatar,
  StatusLabel,
  ManagementTable,
  Notification,
} from "@cea/ui-kit";
import { useApolloClient } from "@apollo/client";
import { useNavigate, Link, useParams } from "react-router-dom";
import advancedFormat from "dayjs/plugin/advancedFormat";

import Appshell from "../../../components/Appshell";
import {
  FETCH_PROJECT_MANAGERS,
  FETCH_QA_PROJECTS,
} from "../../../queries/qaServices";
import { usePagination } from "../../../hooks";
import PMListModal from "../PMListModal/PMListModal";
import AuthContext from "../../../contexts/AuthContext";
import { colorsMap, getNotification } from "../../../utils";
import ClientInviteModal from "../../../components/ClientInviteModal";
import OrganizationContext from "../../../contexts/OrganizationContext";

import styles from "./qa-service-details.module.css";

dayjs.extend(advancedFormat);

const QAServiceDetail = () => {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [serviceDetails, setServiceDetails] = useState({ data: [] });
  const [projectManagers, setProjectManagers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientModalOpen, setClientModalOpen] = useState(false);

  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { organizationId, serviceId } = useParams();
  const { currentPage, pageLimit, onPaginationChange } = usePagination();

  const {
    service,
    organization,
    fetchOrganizationDetails,
    fetchServiceDetails,
  } = useContext(OrganizationContext);
  const { user } = useContext(AuthContext);

  const columns = [
    {
      title: "Project Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Status",
      dataIndex: "PROJECT_STATUS",
      key: "PROJECT_STATUS",
      align: "center",
      render: (record) => <StatusLabel type="string" value={record?.Name} />,
    },
    {
      title: "Progress",
      dataIndex: "CP_Progress",
      key: "CP_Progress",
      align: "center",
      render: (record) => (
        <StatusLabel type="percentage" value={Number(record)} />
      ),
    },
    {
      title: "Risk Status",
      dataIndex: "CP_RiskStatus",
      key: "CP_RiskStatus",
      render: (record) => <StatusLabel type="string" value={record} />,
    },
    {
      title: "Last Updated",
      key: "LastUpdated",
      render: (record, other) => (
        <StatusLabel
          type="date"
          value={{
            date: dayjs(other.LastUpdate).format("MMM D, YYYY HH:mm"),
            username: other.LastUpdatedBy.DisplayName,
          }}
        />
      ),
    },
    {
      title: "Action",
      align: "center",
      width: 150,
      render: (record) => (
        <Link
          key={record.id}
          to={`/organizations/${organizationId}/services/qa/projects/${record.PID}`}
        >
          <div className="standard-flex gap-1 text-center">
            View Project
            <Icon name="arrow_lined_right" size={14} color="#1890ff" />
          </div>
        </Link>
      ),
    },
  ];

  const fetchProjects = async () => {
    try {
      setLoadingProjects(true);

      const selectedSubscription = organization.subscriptions.find(
        (item) => item.service.id === service.id
      );

      const { data } = await apolloClient.query({
        query: FETCH_QA_PROJECTS,
        variables: {
          limit: 200,
          where: {
            ClientID: organization.clientId,
          },
        },
      });

      const { data: pmData } = await apolloClient.query({
        query: FETCH_PROJECT_MANAGERS,
        variables: {
          where: { subscription: selectedSubscription.id },
        },
      });

      setServiceDetails({
        data: data.qaProjects.data,
        total: data.qaProjects.total,
      });
      setProjectManagers(pmData.getProjectManagers);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setLoadingProjects(false);
    }
  };

  useEffect(() => {
    if (!organization) {
      fetchOrganizationDetails(organizationId);
    }
  }, [organization]);

  useEffect(() => {
    if (!service) {
      const filterQuery = {};

      if (serviceId) {
        filterQuery.id = serviceId;
      } else {
        filterQuery.serviceType = "qa";
      }

      fetchServiceDetails(filterQuery);
    }

    if (organization && service) {
      fetchProjects();
    }
  }, [service]);

  const onPaginationUpdate = (page) => {
    onPaginationChange(page);

    navigate(`?page=${page}`);
  };

  const onEditService = () => {
    // Option 1:Change
    setIsModalOpen(true);
  };

  const onAddClient = () => {
    setClientModalOpen(true);
  };

  const closeModal = (type) => {
    if (type === "pm") {
      setIsModalOpen(false);
    } else if (type === "client") {
      setClientModalOpen(false);
    }
  };

  const onAddPM = (serviceToPMData) => {
    setProjectManagers((prevState) => [...prevState, serviceToPMData]);
  };

  const onRemovePM = (serviceToPMId) => {
    setProjectManagers((prevState) =>
      prevState.filter((item) => item.id !== serviceToPMId)
    );
  };

  const getStartingDate = () => {
    const selectedSubscription = organization?.subscriptions.find(
      (item) => item.service.id === service.id
    );

    if (selectedSubscription) {
      return dayjs(selectedSubscription.startingOn).format("Do MMMM, YYYY");
    }
  };

  const isLoggedInUserAdmin = () =>
    user?.roles?.find(
      (item) => item.serivceId === serviceId && item.role === "admin"
    );

  const onRelatedFilesClick = () => {
    navigate(`/organizations/${organizationId}/services/qa/relatedFiles`);
  };

  return (
    <Appshell>
      {(() => {
        // Todo: Fix Loader styles
        if (!service) {
          return (
            <div className="p-8">
              <Loader tip="Loading" />
            </div>
          );
        }

        return (
          <div className="page-main-container">
            <div className="breadcrumb-holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`/organizations/${organization?.id}`}>
                    {organization?.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{service?.name}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <section>
              <Card>
                <Row justify="space-between">
                  <Column span={8}>
                    <div className={styles["primary-text"]}>Service Info</div>
                    <div>Service Based Information</div>
                  </Column>
                  <Column span={16}>
                    <div className="standard-flex justify-end gap-4">
                      <Button
                        className="gap-2"
                        type="primary"
                        icon={<Icon size={14} name="file" color="#fff" />}
                        onClick={onRelatedFilesClick}
                      >
                        Related Files
                      </Button>
                      {isLoggedInUserAdmin ? (
                        <Button type="textx" onClick={onEditService}>
                          Edit Project Managers
                        </Button>
                      ) : null}
                      <Button
                        className="gap-2"
                        type="primary"
                        icon={<Icon size={14} name="plus" color="#fff" />}
                        onClick={onAddClient}
                      >
                        Add Client User
                      </Button>
                    </div>
                  </Column>
                </Row>
                <br />
                <Row>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Service Admins
                    </div>
                    <div>
                      <Avatar.Group maxCount={2}>
                        {service?.admins.map((item) => (
                          <Avatar
                            key={item.id}
                            style={{
                              backgroundColor:
                                colorsMap[item.name[0].toLowerCase()],
                            }}
                          >
                            {item?.name
                              .split(" ")
                              .map((name) => name[0])
                              .join("")}
                          </Avatar>
                        ))}
                      </Avatar.Group>
                    </div>
                  </Column>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Project Managers
                    </div>
                    <div>
                      <Avatar.Group maxCount={2}>
                        {projectManagers.map(({ user }) => {
                          const initials = user.name
                            .split(" ")
                            .map((item) => item[0]);

                          return (
                            <Avatar
                              key={user.id}
                              style={{
                                backgroundColor:
                                  colorsMap[initials[0].toLowerCase()],
                              }}
                            >
                              {initials}
                            </Avatar>
                          );
                        })}
                      </Avatar.Group>
                    </div>
                  </Column>
                  <Column span={4}>
                    <div className={styles["info-section-title"]}>
                      Starting Date
                    </div>
                    <div className={styles["primary-text"]}>
                      {getStartingDate()}
                    </div>
                  </Column>
                </Row>
              </Card>
            </section>
            <br />
            <section>
              <ManagementTable
                columns={columns}
                loading={loadingProjects}
                data={serviceDetails.data}
                pageSize={pageLimit}
                currentPage={currentPage}
                listSize={serviceDetails.total}
                onPageChange={onPaginationUpdate}
                isPaginated
              />
            </section>
          </div>
        );
      })()}
      <PMListModal
        data={projectManagers}
        isVisible={isModalOpen}
        handleCancel={() => closeModal("pm")}
        onAddPM={onAddPM}
        onRemovePM={onRemovePM}
      />
      <ClientInviteModal
        isVisible={clientModalOpen}
        handleCancel={() => closeModal("client")}
      />
    </Appshell>
  );
};

export default QAServiceDetail;
